import { SerializeFrom } from '@remix-run/node';
import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  ReactNode,
} from 'react';
import { HoneypotProvider } from 'remix-utils/honeypot/react';
import { loader } from '~/root';

export type LoginModalPage = 'initial' | 'login' | 'signup';

export type ActionAfterLogin = {
  returnTo?: string;
  queryParams?: Record<string, string>;
};

type LoginModalContextType = {
  isOpen: boolean;
  currentPage: LoginModalPage;
  actionAfterLogin: ActionAfterLogin | null;
  initialTitle?: React.ReactNode;
  openModal: (opts: {
    page?: LoginModalPage;
    actionAfterLogin?: ActionAfterLogin;
    initialTitle?: React.ReactNode | null;
  }) => void;
  closeModal: () => void;
  navigateToPage: (page: LoginModalPage) => void;
  setActionAfterLogin: (actionAfterLogin: ActionAfterLogin | null) => void;
};

const LoginModalContext = createContext<LoginModalContextType | undefined>(
  undefined,
);

type LoginModalProviderProps = {
  children: ReactNode;
  rootLoaderData?: SerializeFrom<typeof loader>;
};

export function LoginModalProvider({
  children,
  rootLoaderData,
}: LoginModalProviderProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState<LoginModalPage>('initial');
  const [actionAfterLogin, setActionAfterLogin] =
    useState<ActionAfterLogin | null>(null);
  const [initialTitle, setInitialTitle] = useState<React.ReactNode | null>(
    null,
  );

  const openModal = useCallback(
    ({
      page = 'initial',
      actionAfterLogin,
      initialTitle,
    }: {
      page?: LoginModalPage;
      actionAfterLogin?: ActionAfterLogin;
      initialTitle?: React.ReactNode | null;
    }) => {
      if (initialTitle) {
        setInitialTitle(initialTitle);
      }
      if (actionAfterLogin) {
        setActionAfterLogin(actionAfterLogin);
      }
      setCurrentPage(page);
      setIsOpen(true);
    },
    [],
  );

  const closeModal = useCallback(() => {
    setIsOpen(false);
    setCurrentPage('initial');
    setInitialTitle(null);
  }, []);

  const navigateToPage = useCallback((page: LoginModalPage) => {
    setCurrentPage(page);
  }, []);

  const value = {
    isOpen,
    currentPage,
    actionAfterLogin,
    initialTitle,
    openModal,
    closeModal,
    navigateToPage,
    setActionAfterLogin,
  };

  return (
    <LoginModalContext.Provider value={value}>
      {rootLoaderData?.honeypotInputProps ? (
        <HoneypotProvider {...rootLoaderData.honeypotInputProps}>
          {children}
        </HoneypotProvider>
      ) : (
        children
      )}
    </LoginModalContext.Provider>
  );
}

// Custom hook to use the login modal context
export function useLoginModal() {
  const context = useContext(LoginModalContext);
  if (context === undefined) {
    throw new Error('useLoginModal must be used within a LoginModalProvider');
  }
  return context;
}
