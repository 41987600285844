import { DataWrapped, TravelGoal } from '@cardo/types';
import { useFetcher } from '@remix-run/react';
import React, { createContext, useContext, ReactNode, useEffect } from 'react';
import { useOptionalUser } from '~/lib/utils';

interface UserData {
  tripReportIds: number[];
  travelGoals: DataWrapped<TravelGoal[]>;
}

interface UserDataContextType {
  tripReportIds: number[] | undefined;
  travelGoals: TravelGoal[] | undefined;
}

const UserDataContext = createContext<UserDataContextType>({
  tripReportIds: undefined,
  travelGoals: undefined,
});

export const UserDataProvider: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const user = useOptionalUser();
  const travelGoalsFetcher = useFetcher<UserData>();

  useEffect(
    function getTravelGoalsData() {
      if (user?.id) {
        travelGoalsFetcher.load('/resource/travel-goal-lists');
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user],
  );

  const providerValue: UserDataContextType = {
    tripReportIds: travelGoalsFetcher.data?.tripReportIds,
    travelGoals: travelGoalsFetcher.data?.travelGoals?.data,
  };

  return (
    <UserDataContext.Provider value={providerValue}>
      {children}
    </UserDataContext.Provider>
  );
};

export const useUserData = () => {
  const context = useContext(UserDataContext);
  if (context === undefined) {
    throw new Error('useUserData must be used within a UserDataProvider');
  }
  return context;
};
